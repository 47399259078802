import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoadingBar from 'react-top-loading-bar';


const LinksPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [links, setLinks] = useState([])
  const [loading, setLoading] = useState(false)
  const [lbar, setLbar] = useState(0)

  useEffect(() => {
    async function fetchLinks(type) {
      setLoading(true)
      setLbar(10)
      const response = await fetch("https://api2.xavi.net/graphql", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query {
                      links(sort: "updated_at:desc"){
                        name,
                        link,
                        description,
                        id  
                      }
                    }`
        }),
      })
      if (response.status !== 200) { 
        setLinks([])
      } else {
        setLbar(50)
        const data = await response.json()
        setLinks(data.data.links)
      }
      setLoading(false)
      setLbar(100)
    }
    fetchLinks()
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Enlaces de interés" />
      <LoadingBar
          progress={lbar}
          height={3}
          color='yellow'
        />
      <h1>Enlaces de interés</h1>
      <dl>
        {!loading && links.map(( node ) => {
          return(
            <dt key={node.id}>
              <a href={node.link} target="_blank" rel="noopener noreferrer">{node.name}</a>{node.description && (<span> {node.description}</span>)}
            </dt>
          )
        })}
      </dl>
    </Layout>
  )
}

export default LinksPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`